import axios from "axios";
import { getStoredValue, setLocalStorage } from "./core/useStorage";
import { toast } from "react-toastify";
import { appRoutes, AppRoutesModel } from "./core/appRoutes";

export const serverPath: string = 'http://front-end-dev.vendingrobotics.eu';
export const socketPath: string = 'ws://front-end-dev.vendingrobotics.eu';
const login = (appRoutes.find((route: AppRoutesModel) => route.routeName === 'login')?.path ?? '');

export const axiosInstance = axios.create({
    baseURL: serverPath,
});

let isRefreshing = false;
let failedQueue: Array<any> = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axiosInstance.interceptors.request.use(
    config => {
        const token = getStoredValue('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axiosInstance(originalRequest);
                }).catch(err => {
                    window.location.href = login;
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
                refreshToken()
                    .then((res: any) => {
                        setLocalStorage('token', res?.token);
                        setLocalStorage('ref_token', res?.refresh_token);
                        axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + res?.token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + res?.token;
                        processQueue(null, (res?.token as any));
                        resolve(axiosInstance(originalRequest));
                    }).catch(err => {
                        processQueue(err, null);
                        reject(err);
                    }).finally(() => {
                        isRefreshing = false;
                    });
            });
        }
        if (toast) toast.dismiss();
        toast.error(error?.response?.data?.message ?? error?.response?.statusText)

        return Promise.reject(error);
    }
);

const refreshToken = async (): Promise<string> => {
    // Replace this with your token refresh logic
    const response = await axios.post(serverPath + '/api/v1/user/refresh-token', {
        token: getStoredValue('ref_token') ?? '',
        refresh_token: getStoredValue('ref_token') ?? ''
    });
    return response.data;
};

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';

import router from './routers';
import './index.css';
import './assets/styles/_styles.scss';
import LoaderComponent from './components/Loader';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { WebSocketProvider } from './core/webSocketProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoaderComponent />}>
      <WebSocketProvider>

        <RouterProvider router={router} />
      </WebSocketProvider>
      <ToastContainer limit={1} hideProgressBar={true} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
